import { defineStore } from 'pinia';
import { ref } from 'vue';
import Localforage from 'localforage';

const REDIRECT_URL = 'redirectUrl';

export const useCommonStore = defineStore('common', () => {
  const darkMode = ref<boolean>(false);

  const isDark = () => {
    return darkMode.value;
  };

  const toggleDarkMode = () => {
    darkMode.value = !darkMode.value;
  };

  const setRedirectUrl = async (url: string | undefined) => {
    await Localforage.setItem(REDIRECT_URL, url);
  };

  const getRedirectUrl = async (): Promise<string | null> => {
    return Localforage.getItem(REDIRECT_URL);
  };

  const removeRedirectUrl = async () => {
    await Localforage.removeItem(REDIRECT_URL);
  };

  const reset = () => {
    darkMode.value = false;
    removeRedirectUrl();
  };

  return {
    isDark,
    toggleDarkMode,
    setRedirectUrl,
    getRedirectUrl,
    removeRedirectUrl,
    reset,
  };
});
